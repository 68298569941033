
/** @jsx jsx */

import MySEO from '../components/seo'

// import Link from '../components/Link'
import {Grid,Box,jsx,Text,Heading} from 'theme-ui'
import Layout from '../components/layout'
import { StaticImage } from 'gatsby-plugin-image'
export default function StGoarzeigtGesicht(){
    return(
        <Layout>
      


<MySEO title="St. Goar zeigt Gesicht" description="Städtische Aktion in St. Goar mi Bürgerbeteiligung" />

<Heading sx={{color:'text',marginBottom:30}}>St. Goar zeigt Gesicht: 3 Fragen an Bürger:innen aus der Gemeinde</Heading>
<Grid sx={{marginBottom:80}} gap={53} columns={[1,2]}>
    <Box>
      <Text>
     <span sx={{fontWeight:700}}>Wir wollen ins Gespräch kommen!</span> Eine Plakataktion mit 
Bürgerbeteiligung zur Belebung der Innenstadt St. Goars und zur Befruchtung des gegenseitigen Austausches. Eine Abschlussveranstaltung mit Podiumsdiskussion ist für September geplant.
      </Text>
      <Text>Neben der Plakataktion werden alle Beiträge auf einer Webseite präsentiert.</Text>
      <Heading sx={{marginTop:40}}>Die Idee</Heading>
      <Text>
      Etwa 24 Plakate mit Portrait und Interview (siehe rechts) sollen die Schaufenster der Heerstraße in St. Goar schmücken. 
       Die Auswahl dieser Plakat-Beiträge erfolgt in Zusammenarbeit mit unserem Verein K.O.M.. </Text><Text sx={{textDecoration:'underline',display:"block"}}></Text>
      <Text>
      Eine Abschlussveranstaltung soll den
bürgerschaftlichen Austausch fördern und Lust auf mehr machen.<span sx={{fontWeight:700}}> ALLE sind herzlich eingeladen mitzumachen!</span>

      </Text>
      <Heading sx={{marginTop:40}}>Dürfen wir Sie fotografieren?</Heading>
      <Text>
      
Für das Plakat und die Internetpräsenz würden wir gerne ein Portrait von Ihnen machen. Das Foto wird ohne zusätzliches Kunstlicht, wird also ohne großen Aufwand, im natürlichen Umfeld der zur portraitierenden Person erstellt. Auf Wunsch wird nur der Vorname der Person genannt.
</Text>
<Text>
<span sx={{fontWeight:700,letterSpacing: '.09rem'}}>ANSPRECHPARTNERIN</span> ist Katrin Gloggengießer via Email: 
kontakt@kulturnetz-oberes-mittelrheintal.org
mit BETREFF: St. Goar
      </Text>
      <Text>
          In Kürze finden Sie hier den Link zur zugehörigen Webseite.
</Text>
<Text sx={{marginTop:40}}>

Das Projekt wird gefördert:<br/>



</Text>
<StaticImage src="../images/foerderung/Logo_Lag.png" layout='fixed' width={150} height={150} alt="Logo Lag" />


    </Box>


    <Box>
        <Box>
        <StaticImage src="../images/projekte/goar/plakat1.jpg" />
        </Box>
        <Box>
        <StaticImage src="../images/projekte/goar/plakat2.jpg" />
        </Box>
        
    </Box>

</Grid>


</Layout>
    )
}
